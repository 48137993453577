"use client";

import Link from "next/link";

import { cn } from "@gsa/ui/lib/utils";
import {
  NavigationMenu,
  NavigationMenuContent,
  NavigationMenuItem,
  NavigationMenuLink,
  NavigationMenuList,
  NavigationMenuTrigger,
  navigationMenuTriggerStyle,
  NavigationMenuViewport,
} from "@gsa/ui/navigation-menu";

export default function HeaderNav() {
  return (
    <NavigationMenu className="hidden lg:flex">
      <NavigationMenuList>
        <NavigationMenuItem>
          <NavigationMenuTrigger className="uppercase">
            What we buy
          </NavigationMenuTrigger>
          <NavigationMenuContent className=" p-4">
            <Link href="/what-we-buy/currency" legacyBehavior passHref>
              <NavigationMenuLink className={navigationMenuTriggerStyle()}>
                Currency
              </NavigationMenuLink>
            </Link>

            <Link href="/what-we-buy/watches" legacyBehavior passHref>
              <NavigationMenuLink className={navigationMenuTriggerStyle()}>
                Watches
              </NavigationMenuLink>
            </Link>

            <Link href="/what-we-buy/jewelry" legacyBehavior passHref>
              <NavigationMenuLink className={navigationMenuTriggerStyle()}>
                Jewelry
              </NavigationMenuLink>
            </Link>

            <Link href="/what-we-buy/bullion" legacyBehavior passHref>
              <NavigationMenuLink className={navigationMenuTriggerStyle()}>
                Bullion
              </NavigationMenuLink>
            </Link>

            <Link href="/what-we-buy/coins" legacyBehavior passHref>
              <NavigationMenuLink className={navigationMenuTriggerStyle()}>
                Coins
              </NavigationMenuLink>
            </Link>
          </NavigationMenuContent>
        </NavigationMenuItem>

        <NavigationMenuItem>
          <Link href="/#how-it-works" legacyBehavior passHref>
            <NavigationMenuLink
              className={cn("uppercase", navigationMenuTriggerStyle())}
            >
              How it works
            </NavigationMenuLink>
          </Link>
        </NavigationMenuItem>

        <NavigationMenuItem>
          <Link href="/#why-goldslayer" legacyBehavior passHref>
            <NavigationMenuLink
              className={cn("uppercase", navigationMenuTriggerStyle())}
            >
              Why Goldslayer
            </NavigationMenuLink>
          </Link>
        </NavigationMenuItem>

        <NavigationMenuItem>
          <Link href="/contact-us" legacyBehavior passHref>
            <NavigationMenuLink
              className={cn("uppercase", navigationMenuTriggerStyle())}
            >
              Contact Us
            </NavigationMenuLink>
          </Link>
        </NavigationMenuItem>
      </NavigationMenuList>
    </NavigationMenu>
  );
}
