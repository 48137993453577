import(/* webpackMode: "eager" */ "/vercel/path0/apps/goldslayer/src/app/globals.css");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/apps/goldslayer/src/app/ui/google-recaptcha-wrapper.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["CSPostHogProvider"] */ "/vercel/path0/apps/goldslayer/src/app/ui/posthog.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/apps/goldslayer/src/components/facebook-pixel.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/apps/goldslayer/src/components/layout/header-nav.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/apps/goldslayer/src/components/scroll-to-top-button.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/apps/goldslayer/src/lib/loader.ts");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/@next+third-parties@14.2.3_next@15.0.0-canary.96_@babel+core@7.24.5_@playwright+test@1.45.3_r_w2efdcs73thyzq3fdegy3hiplq/node_modules/@next/third-parties/dist/google/ga.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/@next+third-parties@14.2.3_next@15.0.0-canary.96_@babel+core@7.24.5_@playwright+test@1.45.3_r_w2efdcs73thyzq3fdegy3hiplq/node_modules/@next/third-parties/dist/google/gtm.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/@next+third-parties@14.2.3_next@15.0.0-canary.96_@babel+core@7.24.5_@playwright+test@1.45.3_r_w2efdcs73thyzq3fdegy3hiplq/node_modules/@next/third-parties/dist/ThirdPartyScriptEmbed.js");
;
import(/* webpackMode: "eager", webpackExports: ["Analytics"] */ "/vercel/path0/node_modules/.pnpm/@vercel+analytics@1.2.2_next@15.0.0-canary.96_@babel+core@7.24.5_@playwright+test@1.45.3_reac_kgxdvralvgf2m6hhoz63wvu7ra/node_modules/@vercel/analytics/dist/react/index.mjs");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/next@15.0.0-canary.96_@babel+core@7.24.5_@playwright+test@1.45.3_react-dom@19.0.0-rc-e56f4ae3_bom7ufjhs4tb6woiznot7druka/node_modules/next/dist/client/image-component.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/next@15.0.0-canary.96_@babel+core@7.24.5_@playwright+test@1.45.3_react-dom@19.0.0-rc-e56f4ae3_bom7ufjhs4tb6woiznot7druka/node_modules/next/dist/client/link.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/next@15.0.0-canary.96_@babel+core@7.24.5_@playwright+test@1.45.3_react-dom@19.0.0-rc-e56f4ae3_bom7ufjhs4tb6woiznot7druka/node_modules/next/dist/client/script.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/next@15.0.0-canary.96_@babel+core@7.24.5_@playwright+test@1.45.3_react-dom@19.0.0-rc-e56f4ae3_bom7ufjhs4tb6woiznot7druka/node_modules/next/font/google/target.css?{\"path\":\"src/lib/fonts.ts\",\"import\":\"Inter\",\"arguments\":[{\"subsets\":[\"latin\"]}],\"variableName\":\"inter\"}");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/next@15.0.0-canary.96_@babel+core@7.24.5_@playwright+test@1.45.3_react-dom@19.0.0-rc-e56f4ae3_bom7ufjhs4tb6woiznot7druka/node_modules/next/font/google/target.css?{\"path\":\"src/lib/fonts.ts\",\"import\":\"Playfair_Display\",\"arguments\":[{\"subsets\":[\"latin\"]}],\"variableName\":\"playfair\"}");
;
import(/* webpackMode: "eager", webpackExports: ["Sheet","SheetTrigger","SheetContent"] */ "/vercel/path0/packages/ui/components/ui/sheet.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Toaster"] */ "/vercel/path0/packages/ui/components/ui/sonner.tsx");
