"use client";

import { useEffect, useState } from "react";

import { Button } from "@gsa/ui/components/ui/button";
import { ChevronUpCircleIcon } from "@gsa/ui/icons";
import { cn } from "@gsa/ui/lib/utils";

export default function ScrollToTopButton() {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const toggleVisibility = () => {
      // if the user scrolls down, show the button
      window.scrollY > 500 ? setIsVisible(true) : setIsVisible(false);
    };
    // listen for scroll events
    window.addEventListener("scroll", toggleVisibility);

    // clear the listener on component unmount
    return () => {
      window.removeEventListener("scroll", toggleVisibility);
    };
  }, []);

  // handles the animation when scrolling to the top
  const scrollToTop = () => {
    isVisible &&
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
  };

  return (
    <Button
      title="Scroll to top"
      variant="link"
      size="icon"
      className={cn(
        "fixed bottom-5 left-5 z-20 rounded-full bg-white/25 p-0 outline-none transition-opacity duration-200 hover:bg-white",
        isVisible ? "opacity-100" : "opacity-0",
      )}
      onClick={scrollToTop}
    >
      <ChevronUpCircleIcon className="h-12 w-12" />
    </Button>
  );
}
